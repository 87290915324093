<template>
  <div>
    <q-dialog
      v-model="showModel"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card-section class="bg-white text-black">
        <q-toolbar class="text-black bg-white">
          <q-toolbar-title>
            <strong>{{ getTitle }}</strong>
          </q-toolbar-title>
          <q-btn dense flat icon="close" v-close-popup @click="resetValue">
          </q-btn>
        </q-toolbar>
        <q-separator />
        <br />
        <div class="q-pa-md">
          <div class="row max-width-100%">
            <q-form @submit="isNew ? save() : update()" class="col">
              <q-input
                class="col col-md-8"
                dense
                style="max-width: 10%; margin-bottom: 15px"
                outlined
                mask="####"
                v-model="importanza"
                :prefix="getPrefix"
                label="Importanza"
              />
              <q-input
                outlined
                v-model="slug"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Slug"
              />
              <q-input
                outlined
                v-model="name"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Name"
              />
              <q-input
                outlined
                v-model="subtitle"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Sub Title"
              />
              <!-- <label><small>Abstract</small></label>
              <q-input
                style="max-width: 80%; height: 100px; margin-bottom: 15px"
                type="textarea"
                outlined
                v-model="abstract"
                label="Abstract"
              /> -->
              <label><small>Abstract</small></label>
              <q-editor
                ref="size"
                outlined
                v-model="abstract"
                style="max-width: 80%; margin-bottom: 15px"
                dense
                placeholder="Abstract"
                :toolbar="[
                  [
                    'bold',
                    'italic',
                    'strike',
                    'underline',
                    'subscript',
                    'superscript'
                  ],
                  ['token', 'hr', 'link', 'custom_btn'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                  ['undo', 'redo']
                ]"
              />
              <!-- la seguente textarea è stat aggiunta in modo da permettere l'inserimento di html puro -->
              <q-input v-model="abstract" outlined dense type="textarea" />
              <label><small>Sizes</small></label>
              <q-editor
                ref="size"
                outlined
                v-model="sizes"
                style="max-width: 80%; margin-bottom: 15px"
                dense
                placeholder="Sizes"
                :toolbar="[
                  [
                    'bold',
                    'italic',
                    'strike',
                    'underline',
                    'subscript',
                    'superscript'
                  ],
                  ['token', 'hr', 'link', 'custom_btn'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                  ['undo', 'redo']
                ]"
              />
              <!-- la seguente textarea è stat aggiunta in modo da permettere l'inserimento di html puro -->
              <q-input v-model="sizes" outlined dense type="textarea" />
              <label><small>Configurations</small></label>
              <q-editor
                outlined
                ref="configuration"
                v-model="configurations"
                style="max-width: 80%; margin-bottom: 15px"
                dense
                placeholder="Configurations"
                :toolbar="[
                  [
                    'bold',
                    'italic',
                    'strike',
                    'underline',
                    'subscript',
                    'superscript'
                  ],
                  ['token', 'hr', 'link', 'custom_btn'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                  ['undo', 'redo']
                ]"
              />
              <!-- la seguente textarea è stat aggiunta in modo da permettere l'inserimento di html puro -->
              <q-input
                v-model="configurations"
                outlined
                dense
                type="textarea"
              />
              <div class="row" style="max-width: 80%">
                <div class="col-md-6">
                  <label><small>Info Column 1</small></label>
                  <q-editor
                    outlined
                    type="textarea"
                    style="margin-right: 10px; margin-bottom: 15px"
                    ref="column1"
                    v-model="moreinfo_column1"
                    placeholder="Info Column 1"
                    :toolbar="[
                      [
                        'bold',
                        'italic',
                        'strike',
                        'underline',
                        'subscript',
                        'superscript'
                      ],
                      ['token', 'hr', 'link', 'custom_btn'],
                      [
                        {
                          label: $q.lang.editor.formatting,
                          icon: $q.iconSet.editor.formatting,
                          list: 'no-icons',
                          options: [
                            'p',
                            'h1',
                            'h2',
                            'h3',
                            'h4',
                            'h5',
                            'h6',
                            'code'
                          ]
                        },
                        'removeFormat'
                      ],
                      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                      ['undo', 'redo']
                    ]"
                  />
                </div>
                <div class="col-md-6">
                  <label><small>Info Column 2</small></label>
                  <q-editor
                    outlined
                    type="textarea"
                    ref="column2"
                    v-model="moreinfo_column2"
                    placeholder="Info Column 2"
                    :toolbar="[
                      [
                        'bold',
                        'italic',
                        'strike',
                        'underline',
                        'subscript',
                        'superscript'
                      ],
                      ['token', 'hr', 'link', 'custom_btn'],
                      [
                        {
                          label: $q.lang.editor.formatting,
                          icon: $q.iconSet.editor.formatting,
                          list: 'no-icons',
                          options: [
                            'p',
                            'h1',
                            'h2',
                            'h3',
                            'h4',
                            'h5',
                            'h6',
                            'code'
                          ]
                        },
                        'removeFormat'
                      ],
                      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                      ['undo', 'redo']
                    ]"
                  />
                </div>
              </div>

              <br />

              <div>
                <q-btn
                  v-if="isNew"
                  label="SAVE"
                  type="submit"
                  color="black"
                  v-close-popup
                />
                <q-btn
                  v-if="!isNew"
                  label="UPDATE"
                  type="submit"
                  color="black"
                  v-close-popup
                />
              </div>
            </q-form>
            <q-form class="col-6 col-md-4">
              <q-uploader
                v-if="this.cover === ''"
                :url="uploadUrl"
                label="Cover"
                style="max-width: 300px; margin-bottom: 20px"
                @uploaded="setCover"
                color="white"
                text-color="black"
              />
              <q-card
                class="col-md-3 text-subtitle text-center"
                style="margin: 10px"
                v-if="this.cover !== ''"
              >
                <q-img :src="getImageCover" style="ratio: 1">
                  <div class="absolute-bottom-right text-subtitle1 text-right">
                    <q-btn no-caps color="red" @click="deleteCover()">
                      <q-icon left size="2em" name="delete" />
                      <div>Delete</div>
                    </q-btn>
                  </div>
                  <div class="absolute-bottom-left text-subtitle1 text-left">
                    Cover Preview
                  </div>
                </q-img>
              </q-card>

              <q-uploader
                v-if="this.imgproducts === ''"
                :url="uploadUrl"
                label="Image Product"
                style="max-width: 300px"
                @uploaded="setImgProd"
                color="white"
                text-color="black"
              />
              <q-card
                class="col-md-3"
                style="margin: 10px"
                v-if="this.imgproducts !== ''"
              >
                <q-img :src="getImageProduct" style="ratio: 1">
                  <div class="absolute-bottom-right text-subtitle1 text-right">
                    <q-btn no-caps color="red" @click="deleteImgproducts()">
                      <q-icon left size="2em" name="delete" />
                      <div>Delete</div>
                    </q-btn>
                  </div>
                  <div class="absolute-bottom-left text-subtitle1 text-left">
                    Image Product Preview
                  </div>
                </q-img>
              </q-card>
            </q-form>
          </div>
        </div>
      </q-card-section>
    </q-dialog>
  </div>
</template>

<script>
import { Dialog } from "quasar";
export default {
  name: "ModelDialog",
  props: ["type", "id", "dialog", "dati"],
  data: () => ({
    showModel: null,
    isNew: true,
    title: "",
    maximizedToggle: true,
    importanza: "",
    slug: "",
    name: "",
    sizes: "",
    configurations: "",
    abstract: "",
    subtitle: "",
    moreinfo_column1: "",
    moreinfo_column2: "",
    elements: [],
    uploadUrl: null,
    cover: "",
    imgproducts: ""
  }),

  mounted() {
    this.getCategorie();

    this.uploadUrl = process.env.VUE_APP_BASEURL + "/api/v1/cloud-upload";
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
    getPrefix() {
      return this.$route.params.shortname + "-";
    },
    getTitle() {
      return this.isNew ? "Add Model" : "Edit Model";
    },
    getImageProduct() {
      return (
        "https://res.cloudinary.com/plurimedia/image/upload/" + this.imgproducts
      );
    },
    getImageCover() {
      return "https://res.cloudinary.com/plurimedia/image/upload/" + this.cover;
    }
  },

  watch: {
    showDialog: function(newVal) {
      this.showModel = newVal;
      if (this.showModel) {
        if (this.dati.id) {
          this.getData();
          this.isNew = false;
        } else this.isNew = true;
      }
    }
  },

  methods: {
    /* pasteCapture(evt, type) {
      let text, onPasteStripFormattingIEPaste;
      evt.preventDefault();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData("text/plain");
        this.$refs[type].runCmd("insertText", text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData("text/plain");
        this.$refs[type].runCmd("insertText", text);
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          this.$refs[type].runCmd("ms-pasteTextOnly", text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    }, */

    getData() {
      if (this.dati.type) {
        this.$http
          .get("/api/secure/" + this.dati.type + "/get-by-id/?", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken
            },
            params: { id: this.dati.id }
          })
          .then(results => {
            this.elements = results.data;
            console.log("[Elemento in dialog]:", results.data);
            let elem = results.data[0];
            this.name = elem.name;
            this.importanza = elem.importanza;
            this.slug = elem.slug;
            this.sizes = elem.sizes;
            this.configurations = elem.configurations;
            this.abstract = elem.abstract;
            this.subtitle = elem.subtitle;
            this.moreinfo_column1 = elem.moreinfo_column1;
            this.moreinfo_column2 = elem.moreinfo_column2;
            this.cover = elem.cover;
            this.imgproducts = elem.imgproducts;
          });
      }
    },
    //emit
    resetValue() {
      this.name = "";
      this.importanza = "";
      this.slug = "";
      this.abstract = "";
      this.subtitle = "";
      this.sizes = "";
      this.configurations = "";
      this.moreinfo_column1 = "";
      this.moreinfo_column2 = "";
      this.cover = "";
      this.imgproducts = "";

      this.$emit("reset");
    },

    getCategorie() {
      this.$http
        .get("/api/secure/categoria/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.cat = results.data;
        });
    },

    deleteImgproducts() {
      this.imgproducts = "";
    },
    deleteCover() {
      this.cover = "";
    },

    setCover(info) {
      console.log("Result from cloudinary", info);
      let resp = JSON.parse(info.xhr.responseText);
      // console.log("Json cloudinary", resp);
      this.cover = resp.public_id;
    },

    setImgProd(info) {
      console.log("Result from cloudinary", info);
      let resp = JSON.parse(info.xhr.responseText);
      //console.log("Json cloudinary", resp);
      this.imgproducts = resp.public_id;
    },

    save() {
      let toSave = {
        name: this.name,
        importanza: this.importanza,
        slug: this.slug,
        abstract: this.abstract,
        sizes: this.sizes,
        configurations: this.configurations,
        moreinfo_column1: this.moreinfo_column1,
        moreinfo_column2: this.moreinfo_column2,
        categoria: this.$route.params.id,
        cover: this.cover,
        imgproducts: this.imgproducts,
        subtitle: this.subtitle
      };
      this.$http
        .post("/api/secure/modello/save", toSave, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.$q.notify({
            message: "Salvataggio riuscito",
            color: "green",
            timeout: 1000
          });
          console.info("Salvataggio riuscito");
          this.resetValue();
        })
        .catch(err => {
          this.$q.notify({
            message: "Errore durante il salvataggio",
            color: "red",
            timeout: 1000
          });
          this.resetValue();
        });
    },
    update() {
      let toUpdate = {
        _id: this.dati.id,
        name: this.name,
        importanza: this.importanza,
        slug: this.slug,
        abstract: this.abstract,
        sizes: this.sizes,
        configurations: this.configurations,
        moreinfo_column1: this.moreinfo_column1,
        moreinfo_column2: this.moreinfo_column2,
        cover: this.cover,
        imgproducts: this.imgproducts,
        subtitle: this.subtitle
      };
      this.$http
        .put("/api/secure/modello/update", toUpdate, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.$q.notify({
            message: "Aggiornamento riuscito",
            color: "green",
            timeout: 1000
          });
          console.info(
            "Aggiornamento riuscito modello id:" + this.$route.params.id
          );
          this.resetValue();
        })
        .catch(err => {
          this.$q.notify({
            message: "Errore durante l'aggiornamento",
            color: "red",
            timeout: 1000
          });
          this.resetValue();
        });
    }
  }
};
</script>
