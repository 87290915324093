<template>
  <div class="q-pa-md q-mt-xs q-gutter-y-md">
    <div class="bg-white text-black">
      <q-toolbar>
        <q-toolbar-title>
          <h5 class="q-ma-none">Size Your System Report</h5>
        </q-toolbar-title>
        <q-space />
      </q-toolbar>

      <div class="q-pa-md q-gutter-y-sm">
        <div class="row max-width-100%">
          <q-input
            class="col col-md-8"
            style="max-width: 40%; margin-right: 10px"
            outlined
            color="black"
            dense
            v-model="filter"
            debounce="300"
            placeholder="Search product or category"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <q-btn
          style="float: right"
          size="11px"
          no-caps
          color="black"
          label="Export in CSV"
          @click="exportTable"
        />
      </div>
    </div>

    <div class="q-pa-md q-gutter-y-sm">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        :data="dati"
        :columns="columns"
        row-key="name"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination.sync="pagination"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              outline
              no-caps
              color="black"
              label="Detail"
              @click="show = true"
            />

            <detail-dialog :dialog="show" @reset="reset()"> </detail-dialog>
          </q-td>
        </template>
      </q-table>

      <!-- <div class="row justify-center q-mt-md">
        <q-pagination
          v-model="pagination.page"
          color="grey-8"
          :max="pagesNumber"
          size="md"
          :direction-links="true"
          :boundary-links="true"
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { date } from "quasar";
import DetailDialog from "../components/DetailDialog.vue";
import { exportFile } from "quasar";

export default {
  name: "SizeYourSystemReport",
  components: {
    DetailDialog
  },
  data: () => ({
    show: false,
    dati: [],
    cat_options: [],
    pagination: {
      sortBy: "name",
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "name",
        required: true,
        label: "PRODUCTS",
        align: "left",
        field: "product",
        sortable: true
      },
      {
        name: "categories",
        align: "left",
        label: "CATEGORIES",
        field: "category",
        sortable: true
      },
      {
        name: "show",
        label: "SHOW",
        field: "count",
        sortable: true
      },
      {
        name: "filerequests",
        align: "right",
        label: "FILE REQUESTS",
        field: "requests",
        sortable: true
      }
    ],
    filter: ""
  }),

  mounted() {
    this.getSysData();
  },

  created() {
    //this.getProdotti();
  },
  computed: {
    /* pagesNumber() {
      let tmp = Math.ceil(this.dati.length / this.pagination.rowsPerPage);
      return tmp > 5 ? 5 : tmp;
    }, */
    inputData() {
      return this.$store.getters.getRichieste;
    }
  },
  methods: {
    getSysData() {
      this.$http.get("/api/v1/sys-report").then(results => {
        this.dati = results.data;
      });
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val;

      formatted =
        formatted === void 0 || formatted === null ? "" : String(formatted);

      formatted = formatted.split('"').join('""');

      return `"${formatted}"`;
    },

    exportTable() {
      const content = [this.columns.map(col => this.wrapCsvValue(col.label))]
        .concat(
          this.dati.map(row =>
            this.columns
              .map(col =>
                this.wrapCsvValue(
                  typeof col.field === "function"
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                  col.format
                )
              )
              .join(",")
          )
        )
        .join("\r\n");

      const status = exportFile("SYSReport.csv", content, "text/csv");

      if (status !== true) {
        this.$q.notify({
          message: "Browser denied file download...",
          color: "negative",
          icon: "warning"
        });
      }
    }
  }
};
</script>
