<template>
  <div class="q-pa-md q-mt-xs q-gutter-md">
    <q-toolbar>
      <q-toolbar-title>
        <h5 class="q-ma-none">Dashboard</h5>
      </q-toolbar-title>
    </q-toolbar>
    <div class="dashboard-wrapper">
      <div class="row q-pa-md q-gutter-md">
        <div class="col-12 col-md-5">
          <q-card flat bordered class="my-card">
            <q-card-section>
              <div class="text-h6">
                <q-avatar
                  class="q-mr-md"
                  color="primary"
                  text-color="white"
                  icon="topic"
                />
                Product Summary
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div class="row dashboard-count">
                <div class="col-4 text-center">
                  <h4>{{ numeroCategorie }}</h4>
                  <p>Category</p>
                </div>
                <div class="col-4 text-center">
                  <h4>{{ numeroModelli }}</h4>
                  <p>Models</p>
                </div>
                <div class="col-4 text-center">
                  <h4>{{ numeroProdotti }}</h4>
                  <p>Products</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-12 col-md-5">
          <q-card flat bordered class="my-card">
            <q-card-section>
              <div class="text-h6">
                <q-avatar
                  class="q-mr-md"
                  color="primary"
                  text-color="white"
                  icon="attachment"
                />
                Documents Summary
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div class="row dashboard-count">
                <div class="col-6 text-center">
                  <h4>{{ numeroDocumenti.numberOfFiles }}</h4>
                  <p>File Documents</p>
                </div>
                <div class="col-6 text-center">
                  <h4>{{ numeroDocumenti.numberOfLinks }}</h4>
                  <p>Link Files</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="row q-pa-md q-gutter-md">
        <div class="col-12 col-md-5">
          <q-card flat bordered class="my-card">
            <q-card-section>
              <div class="text-h6">
                <q-avatar
                  class="q-mr-md"
                  color="primary"
                  text-color="white"
                  icon="person"
                />
                User Summary
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div class="row dashboard-count">
                <div class="col-6 text-center">
                  <h4>{{ numeroUtenti }}</h4>
                  <p>User</p>
                </div>
                <div class="col-6 text-center">
                  <h4>{{ numeroRichieste }}</h4>
                  <p>File requests</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-12 col-md-5">
          <q-card flat bordered class="my-card">
            <q-card-section>
              <div class="text-h6">
                <q-avatar
                  class="q-mr-md"
                  color="primary"
                  text-color="white"
                  icon="style"
                />
                Size your System Summary
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div class="row dashboard-count">
                <div class="col-6 text-center">
                  <h4>{{ numeroShows }}</h4>
                  <p>Show</p>
                </div>
                <div class="col-6 text-center">
                  <h4>{{ numeroRichiesteProdotti }}</h4>
                  <p>File requests</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  data: () => ({
    numeroCategorie: "",
    numeroModelli: "",
    numeroProdotti: "",
    numeroDocumenti: "",
    numeroFiles: "",
    numeroUtenti: "",
    numeroRichieste: "",
    numeroShows: "",
    numeroRichiesteProdotti: ""
  }),

  mounted() {
    this.getNumeroCat();
    this.getNumeroMod();
    this.getNumeroProd();
    this.getNumeroRichieste();
    this.getNumeroUtenti();
    this.getNumeroDocumenti();
    this.getNumShows();
    this.getNumRichiesteProd();
  },

  methods: {
    getNumeroCat() {
      this.$http
        .get("/api/secure/categoria/numCategorie", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroCategorie = result.data;
        });
    },

    getNumeroMod() {
      this.$http
        .get("/api/secure/modello/numModello", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroModelli = result.data;
        });
    },

    getNumeroProd() {
      this.$http
        .get("/api/secure/prodotto/numProdotto", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroProdotti = result.data;
        });
    },

    getNumeroRichieste() {
      this.$http
        .get("/api/richiesta/numRichieste", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroRichieste = result.data;
        });
    },

    getNumeroUtenti() {
      this.$http
        .get("/api/richiesta/numUsers", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroUtenti = result.data;
        });
    },

    getNumeroDocumenti() {
      this.$http
        .get("/api/v1/documents-summary", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroDocumenti = result.data;
        });
    },
    getNumShows() {
      this.$http
        .get("/api/datausage/numOfShows", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroShows = result.data;
        });
    },
    getNumRichiesteProd() {
      this.$http
        .get("/api/richiesta/numRichiesteProd", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(result => {
          this.numeroRichiesteProdotti = result.data;
        });
    }
  }
};
</script>

<style>
.dashboard-wrapper .my-card {
  width: 100%;
}
.dashboard-count h4 {
  margin: 1em 0 0;
}
.dashboard-count p {
  margin-bottom: 2em;
}
</style>
