<template>
  <div class="q-pa-sm row items-start q-gutter-md category-card">
    <q-card class="my-card" @click="toTree()">
      <img :src="getImage" />
      <q-card-section>
        <div class="text-h5 text-center">{{ shortname }}</div>
        <div class="text-body1 text-center">{{ name }}</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "CategorieCard",
  props: ["id", "imgproducts", "name", "shortname"],
  computed: {
    getImage() {
      const bucket = process.env.VUE_APP_CLOUDINARY_BUCKET;
      return this.imgproducts
        ? "https://res.cloudinary.com/plurimedia/image/upload/w_230,c_scale/" +
            this.imgproducts
        : "https://res.cloudinary.com/plurimedia/image/upload/v1495121472/plurimedia/placeholder.png";
    }
  },
  methods: {
    toTree() {
      this.$store.commit("setCategoryName", this.name);
      this.$store.commit("setCategoryShortName", this.shortname);

      this.$router.push({
        name: "TreeView",
        params: {
          id: this.id,
          category: this.name,
          shortname: this.shortname
        }
      });
    }
  }
};
</script>

<style>
.category-card .my-card {
  width: 100%;
  width: 300px;
  cursor: pointer;
  padding: 1em;
  transition: transform 0.25s ease-in;
}
.category-card .my-card:hover {
  transform: translate3d(0, -5px, 0);
  border-bottom: 2px solid #11387f;
}
</style>
