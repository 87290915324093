import { render, staticRenderFns } from "./ProductDialog.vue?vue&type=template&id=01ec6abc&"
import script from "./ProductDialog.vue?vue&type=script&lang=js&"
export * from "./ProductDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCardSection,QToolbar,QToolbarTitle,QBtn,QSeparator,QForm,QInput,QEditor,QTable,QSpace,QIcon,QTr,QTd,QPopupEdit,QUploader,QCard,QImg});qInstall(component, 'directives', {ClosePopup});
