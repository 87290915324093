import Vue from "vue";
import Auth0Lock from "auth0-lock";
import store from "../store";
import _ from "lodash";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        accessToken: null,
        profile: null,
        isAuthenticated: false,
        loading: true
      };
    },
    methods: {
      async getUserinfo(authResult) {
        const _this = this;
        this.auth0Client.getUserInfo(authResult.accessToken, function(
          error,
          profileResult
        ) {
          if (error) {
            // Handle error
            return;
          }
          //console.log("qui profileResult", profileResult);
          localStorage.setItem("token", JSON.stringify(authResult.idToken));
          localStorage.setItem("profile", JSON.stringify(profileResult));

          const token_time = process.env.VUE_APP_TOKEN_EXPIRES_TIME;
          if (!localStorage.getItem("expires")) {
            let exp = parseInt(token_time) * 1000 + Date.now(); // 12h modificare il 36000 in base all'expired impostato su Auth0
            localStorage.setItem("expires", exp);
          }

          store.commit("setToken", authResult.idToken);
          //console.log("Token", store.getters.getToken);

          store.commit("setUser", profileResult);

          _this.isAuthenticated = true;
          _this.loading = false;
        });
      },

      async getToken() {
        return this.accessToken;
      },

      async login() {
        this.auth0Client.show(options.lockOptions);
      },

      async logout(redirectUrl) {
        this.isAuthenticated = false;
        localStorage.removeItem("expires");
        localStorage.removeItem("token");
        localStorage.removeItem("profile");
        this.auth0Client.logout({ returnTo: redirectUrl });
        //this.loading = false;
      },

      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          const _this = this;
          await _this.auth0Client.checkSession({}, function(error, authResult) {
            //console.log("check session", authResult, error);
            if (error || !authResult) {
              _this.isAuthenticated = false;
              _this.loading = false;
            } else {
              _this.getUserinfo(authResult);
            }
          });
        } catch (e) {
          console.log("errore", e);
        }
      }
    },

    async created() {
      this.auth0Client = new Auth0Lock(options.clientId, options.domain, {
        rememberLastLogin: true,
        languageDictionary: {
          title: ""
        },
        avatar: null,
        theme: {
          logo: process.env.VUE_APP_LOGO,
          primaryColor: process.env.VUE_APP_COLOR_PRIMARY
        },
        auth: {
          params: {
            scope: "openid email user_metadata app_metadata picture"
          },
          autoParseHash: true,
          redirect: true,
          redirectUrl: redirectUri,
          responseType: "token",
          sso: false
        },
        allowSignUp: false,
        allowForgotPassword: true
      });

      await this.handleRedirectCallback();

      const _this = this;
      this.auth0Client.on("authenticated", function(authResult) {
        console.log("autenticathed", authResult);
        _this.getUserinfo(authResult);
      });

      /*  this.auth0Client.on("show", function() {
        console.log("arriva show");
      });

      this.auth0Client.on("hide", function() {
        console.log("arriva hide");
      });

      this.auth0Client.on("unrecoverable_error", function(arg) {
        console.log("arriva unrecoverable_error", arg);
      });

      this.auth0Client.on("authorization_error", function(arg) {
        console.log("arriva authorization_error", arg);
      });

      this.auth0Client.on("hash_parsed", function(arg) {
        console.log("arriva hash_parsed", arg);
      });

      this.auth0Client.on("forgot_password ready", function(arg) {
        console.log("arriva forgot_password ready", arg);
      });

      this.auth0Client.on("forgot_password submit", function(arg) {
        console.log("arriva forgot_password submit", arg);
      });

      this.auth0Client.on("signin submit", function() {
        console.log("arriva signin submit");
      });

      this.auth0Client.on("signup submit", function(arg) {
        console.log("arriva signup submit", arg);
      });

      this.auth0Client.on("signup success", function(arg) {
        console.log("arriva signup success", arg);
      });

      this.auth0Client.on("signup error", function(arg) {
        console.log("arriva signup error", arg);
      });

      this.auth0Client.on("federated login", function(arg) {
        console.log("arriva federated login", arg);
      });

      this.auth0Client.on("sso login", function(arg) {
        console.log("arriva sso login", arg);
      }); */
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
