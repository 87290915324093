import { render, staticRenderFns } from "./TreeView.vue?vue&type=template&id=cb528ee2&"
import script from "./TreeView.vue?vue&type=script&lang=js&"
export * from "./TreeView.vue?vue&type=script&lang=js&"
import style0 from "./TreeView.vue?vue&type=style&index=0&id=cb528ee2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSplitter,QBtn,QTree});
