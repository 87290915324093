import { render, staticRenderFns } from "./UserDetailDialog.vue?vue&type=template&id=12366e33&"
import script from "./UserDetailDialog.vue?vue&type=script&lang=js&"
export * from "./UserDetailDialog.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailDialog.vue?vue&type=style&index=0&id=12366e33&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCardSection,QToolbar,QToolbarTitle,QBtn,QSeparator,QTable,QTd,QIcon,QPagination});qInstall(component, 'directives', {ClosePopup});
