import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    user: null,
    categoryName: null,
    categoryShortName: null
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setCategoryName(state, value) {
      sessionStorage.setItem("categoryName", value);
      state.categoryName = value;
    },
    setCategoryShortName(state, value) {
      sessionStorage.setItem("categoryShortName", value);
      state.categoryShortName = value;
    }
  },
  actions: {},
  modules: {},
  getters: {
    getToken: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    isAdmin: state => {
      return state.user.roles.indexOf("admin") >= 0 ? true : false;
    },
    getCategoryName: state => {
      return state.categoryName
        ? state.categoryName
        : sessionStorage.getItem("categoryName");
    },
    getCategoryShortName: state => {
      return state.categoryShortName
        ? state.categoryShortName
        : sessionStorage.getItem("categoryShortName");
    }
  }
});
