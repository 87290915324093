<template>
  <div class="full-width column wrap">
    <div class="fit row wrap justify-start">
      <q-uploader
        class=" col-sm-5"
        ref="myUploader"
        :url="uploadUrl"
        :factory="getSignedUrl"
        label="Upload files"
        multiple
        square
        batch
        send-raw
        style="height: 300px;"
        @uploaded="callDb"
      >
      </q-uploader>

      <q-card class="col-sm-5 q-ml-lg" square style="height: 300px;">
        <q-item class="bg-primary text-white text-bold">
          <q-item-section>
            <q-item-label>Add link file</q-item-label>
          </q-item-section>
        </q-item>

        <q-separator />

        <q-card-section horizontal>
          <q-card-section style="width: 100%">
            <q-input
              outlined
              v-model="filenameToAdd"
              label="Document Name"
              style="margin-bottom: 15px"
            />
            <q-input outlined v-model="linkToAdd" label="Link" />

            <q-card-section class="fit row wrap justify-end content-start">
              <q-btn color="primary" @click="addLink()" style="width:150px;"
                >Add</q-btn
              >
            </q-card-section>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>

    <div class="fit row wrap justify-start q-mt-xl">
      <div class="col-md-12 text-right">
        <q-space />
        <q-toggle
          v-model="flagArchive"
          color="primary"
          icon="archive"
          size="xl"
          :label="
            flagArchive ? 'Switch to online data' : 'Switch to archives data'
          "
          left-label
        />
      </div>

      <div class="col-md-12">
        <div style="margin-top: 10px;">
          <q-table
            :title="labelTable"
            :data="dati"
            :columns="columns"
            row-key="name"
            :grid="$q.screen.xs"
            :filter="filter"
            :pagination="pagination"
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="document" :props="props">
                  <q-icon name="create" class="text-blue-grey-4" />
                  {{ props.row.name }}
                  <q-popup-edit
                    ref="inline"
                    v-model="props.row.name"
                    @save="(val, init) => saveInline(val, init)"
                  >
                    <q-input v-model="props.row.name" dense autofocus counter />
                  </q-popup-edit>
                </q-td>

                <q-td key="filename" :props="props"
                  ><span class="text-wrap">{{ props.row.file }}</span></q-td
                >
                <q-td key="ultima_modifica" :props="props">
                  {{ formatDate(props.row.mdate) }}
                </q-td>
                <q-td key="actions" :props="props">
                  <q-btn-dropdown size="12px" color="primary" label="Actions">
                    <q-list>
                      <q-item
                        v-if="!props.row.link"
                        clickable
                        v-close-popup
                        @click="getFile(props)"
                      >
                        <q-item-section>
                          <q-item-label
                            ><q-icon class="q-mr-sm" name="save_alt" /> Download
                            file</q-item-label
                          >
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="!props.row.archive"
                        clickable
                        v-close-popup
                        @click="archiveDialog(props)"
                      >
                        <q-item-section>
                          <q-item-label
                            ><q-icon class="q-mr-sm" name="archive" />
                            Archives</q-item-label
                          >
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="props.row.archive"
                        clickable
                        v-close-popup
                        @click="restoreDialog(props)"
                      >
                        <q-item-section>
                          <q-item-label
                            ><q-icon class="q-mr-sm" name="restore" />
                            Restore</q-item-label
                          >
                        </q-item-section>
                      </q-item>

                      <q-item
                        clickable
                        v-close-popup
                        @click="deleteDialog(props)"
                      >
                        <q-item-section>
                          <q-item-label
                            ><q-icon class="q-mr-sm" name="delete" />
                            Delete</q-item-label
                          >
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </q-td>
              </q-tr>
            </template>

            <template v-slot:top-right>
              <q-input
                filled
                dense
                debounce="300"
                v-model="filter"
                placeholder="Search"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>

          <!-- <div class="row justify-center q-mt-md">
            <q-pagination
              v-model="pagination.page"
              color="grey-8"
              :max="pagesNumber"
              size="md"
              :direction-links="true"
              :boundary-links="true"
              icon-first="skip_previous"
              icon-last="skip_next"
              icon-prev="fast_rewind"
              icon-next="fast_forward"
            />
          </div> -->
        </div>
      </div>
    </div>

    <q-dialog v-model="showDialog" persistent square>
      <q-card class="q-pa-sm">
        <q-card-section>
          <div class="row">
            <div class="text-h6">{{ titleDialog }}</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </div>
          <div class="text-subtitle2 text-blue-grey-8">
            {{ subTitleDialog }}
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row items-center">
            <span v-html="bodyDialog"></span>
          </div>
        </q-card-section>

        <q-card-actions class="q-pr-md q-pb-md" align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn
            :label="dialoglabel"
            color="primary"
            @click="dialogAction"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from "quasar";

/*   N.B: Ogni document sarà un oggetto cosi formato:
  {
    name: "nomefile",
    file: "nomefile.estensione o link",
    mdate: new Date(),
    link: boolean,
    archived: boolean
  } 
*/

export default {
  name: "Uploader",
  props: ["type", "id", "mdate"],
  data: () => ({
    uploadUrl: null,
    dati: [],
    datiOrginal: [],
    labelTable: "Documents",
    pagination: {
      sortBy: "ultima_modifica",
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "document",
        required: true,
        label: "DOCUMENT NAME",
        align: "left",
        field: row => row.name,
        sortable: true
      },
      {
        name: "filename",
        align: "left",
        label: "FILE",
        field: "file",
        sortable: true
      },
      {
        name: "ultima_modifica",
        align: "left",
        label: "LAST EDIT",
        field: "mdate",
        format: val => date.formatDate(val, "DD/MM/YYYY HH:mm:ss"),
        sortable: true
      },
      {
        name: "actions",
        align: "right",
        label: "ACTIONS"
      }
    ],
    filter: "",
    linkToAdd: "",
    filenameToAdd: "",
    showDialog: false,
    titleDialog: "",
    subTitleDialog: "",
    bodyDialog: "",
    dialoglabel: "",
    action: "",
    currentElem: null,
    flagArchive: false
  }),
  computed: {
    /* pagesNumber() {
      let tmp = Math.ceil(this.dati.length / this.pagination.rowsPerPage);
      return tmp > 5 ? 5 : tmp;
    } */
  },
  mounted() {
    this.getData();
    this.uploadUrl = process.env.VUE_APP_BASEURL + "/api/cos/upload";
  },
  watch: {
    flagArchive: function(val) {
      if (val) {
        this.getDataArchived();
        this.labelTable = "Archived documents";
      } else {
        this.getData();
        this.labelTable = "Documenti";
      }
    },
    id: function(val) {
      console.log("UPLOADER id:", val);
      this.getData();
      this.flagArchive = false;
    },
    type: function(val) {
      console.log("UPLOADER type:", val);
    },
    datiOrginal: function(val, old) {
      console.log("cambiato ora:", val, old);
    }
  },
  methods: {
    formatDate(val) {
      return date.formatDate(val, "DD/MM/YYYY HH:mm:ss");
    },

    //update dell'edit inline
    saveInline(newVal, initalVal) {
      console.log("[Field inline edit]", newVal, initalVal);

      let toUpdate = {
        _id: this.id,
        documents: this.datiOrginal
      };

      this.baseUpdate(toUpdate)
        .then(() => {
          this.$q.notify({
            message: "Field successfull updated",
            color: "green",
            timeout: 2000
          });
          console.info(
            "Successfull updated " + this.type + " field id:" + this.id
          );
        })
        .catch(err => {
          this.$q.notify({
            message: "Error on field update",
            color: "red",
            timeout: 2000
          });
        });
    },

    async getSignedUrl(files) {
      for (const file of files) {
        let data = await this.$http.post('api/cos/upload', {
          percorso: file.name,
          size: file.size,
          tipo: file.type
        }, { headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }})

        return {
          url: data.data,
          method: 'PUT'
        }
      }
    },

    //method cos (cloud object storage)
    getFile(elem) {
      this.$http
        .get("/api/cos/get/", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          params: { filename: elem.row.file }
        })
        .then(signed => {
          console.log("signed url", signed);

          this.$http.get(signed.data, {responseType: 'blob'}).then(response => {
            // console.log("risultato", response.data);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var blob = response.data // new Blob([response.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = elem.row.file; //response.config.params.filename;
            a.click();
            window.URL.revokeObjectURL(url);
          });

          /*  */
        });
    },

    //logica di cancellazione lato cos (cloud object storage)
    deleteFile(filename) {
      return this.$http.delete("/api/cos/delete/", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        params: { filename: filename }
      });
      /* .then((response) => {
          console.log("risultato", response);
        }); */
    },

    //recupero dati dal db
    getData() {
      if (this.type) {
        this.$http
          .get("/api/secure/" + this.type + "/get-by-id/?", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken
            },
            params: { id: this.id }
          })
          .then(result => {
            console.log("GET [Documents]", result);
            if (result.data[0].documents.length > 0) {
              this.datiOrginal = result.data[0].documents;
              this.dati = this.$_.filter(result.data[0].documents, el => {
                return !el.archive;
              });
            } else {
              this.datiOrginal = [];
              this.dati = [];
            }
          });
      }
    },

    getDataArchived() {
      if (this.type) {
        this.$http
          .get("/api/secure/" + this.type + "/get-by-id/?", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken
            },
            params: { id: this.id }
          })
          .then(result => {
            console.log("GET [Documents]", result);
            if (result.data[0].documents.length > 0) {
              this.datiOrginal = result.data[0].documents;
              this.dati = this.$_.filter(result.data[0].documents, el => {
                return el.archive;
              });
            } else {
              this.datiOrginal = [];
              this.dati = [];
            }
          });
      }
    },

    baseUpdate(toUpdate) {
      return this.$http.put("/api/secure/" + this.type + "/update", toUpdate, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      });
    },

    baseUpdateDoc(toUpdate) {
      return this.$http.put(
        "/api/secure/" + this.type + "/update-doc",
        toUpdate,
        {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          params: { id: this.id }
        }
      );
    },

    //update dati db
    updateDb(files) {
      let newdocuments = this.datiOrginal;
      let temp = [];

      /* se contiene dei documenti, controllo unicità e aggiungo il nome dei nuovi */
      if (newdocuments.length > 0) {
        files.forEach(el => {
          if (!this.$_.includes(newdocuments, el)) {
            temp.push(el);
          }
        });
      } else temp = files;

      /* ogni nuovo doc da aggiungere viene inserito come oggetto  */
      temp.forEach(el => {
        let obj = {
          name: el,
          file: el,
          mdate: new Date()
        };
        newdocuments.push(obj);
      });

      let toUpdate = {
        _id: this.id,
        documents: newdocuments
      };
      console.log("Documents to update", toUpdate);

      this.baseUpdate(toUpdate)
        .then(results => {
          this.$q.notify({
            message: "Successfull updated",
            color: "green",
            timeout: 2000
          });
          console.info("Successfull updated " + this.type + " id:" + this.id);
          this.flagArchive = false; //nel caso switch di modalità
          this.getData();
        })
        .catch(err => {
          this.$q.notify({
            message: "Error on update",
            color: "red",
            timeout: 2000
          });
        });
    },

    //chiamata post-upload files x aggiornamento db
    callDb(info) {
      console.log("Call db after upload", info);
      let files = this.$_.map(info.files, "name");
      this.updateDb(files);
      this.$refs.myUploader.reset();
    },

    dialogAction() {
      if (this.action === "delete") this.deleteRow(this.currentElem);
      else if (this.action === "archive") this.archiveRow(this.currentElem);
      else this.restoreRow(this.currentElem);
    },

    archiveDialog(element) {
      this.titleDialog = "Archive Document";
      this.subTitleDialog = element.row.file;
      this.bodyDialog =
        "By archiving the file it will no longer be visible on the site, " +
        "but it will be possible to analyze the statistics.<br><br>Are you sure?";
      this.dialoglabel = "Yes, Archive";
      this.showDialog = true;
      this.action = "archive";
      this.currentElem = element;
    },

    archiveRow(element) {
      console.log("archive", element);
      let newRow = element.row;
      newRow.archive = true;

      this.baseUpdateDoc(newRow)
        .then(results => {
          this.$q.notify({
            message: "Successfull archived",
            color: "green",
            timeout: 2000
          });
          console.info("Successfull archived");

          this.getData();
        })
        .catch(err => {
          this.$q.notify({
            message: "Error on archive",
            color: "red",
            timeout: 2000
          });
        });
    },

    restoreDialog(element) {
      this.titleDialog = "Restore Document";
      this.subTitleDialog = element.row.file;
      this.bodyDialog =
        "By resuming this file it will be available online again " +
        "on the site and we will analyze the statistics.<br><br>Are you sure?";
      this.dialoglabel = "Yes, Restore";
      this.showDialog = true;
      this.action = "restore";
      this.currentElem = element;
    },

    restoreRow(element) {
      console.log("restore", element);
      let newRow = element.row;
      newRow.archive = false;

      this.baseUpdateDoc(newRow)
        .then(results => {
          this.$q.notify({
            message: "Successfull restored",
            color: "green",
            timeout: 2000
          });
          console.info("Successfull restored");

          this.getDataArchived();
        })
        .catch(err => {
          this.$q.notify({
            message: "Error on restore",
            color: "red",
            timeout: 2000
          });
        });
    },

    deleteDialog(element) {
      this.titleDialog = "Delete Document";
      this.subTitleDialog = element.row.file;
      this.bodyDialog =
        "Deleting the file will no longer be visible on the site, " +
        "and you will <b>delete the related statistics.</b><br><br>Are you sure?";
      this.dialoglabel = "Yes, Delete Document";
      this.showDialog = true;
      this.action = "delete";
      this.currentElem = element;
    },

    //logica di cancellazione lato db
    deleteDb(element) {
      var newDocuments = this.$_.filter(this.datiOrginal, function(el) {
        return el.name !== element.row.name;
      });

      let toUpdate = {
        _id: this.id,
        documents: newDocuments
      };

      console.log("Document to delete:", toUpdate);

      /* E' una put/update in quanto bisogna solo rimuovere un elemento dall'array documents */
      this.$http
        .put("/api/secure/" + this.type + "/update", toUpdate, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.$q.notify({
            message: "Successfull deleted",
            color: "green",
            timeout: 2000
          });
          console.info("Successfull deleted");

          if (this.flagArchive) this.getDataArchived();
          else this.getData();

          this.action = "";
          this.currentElem = null;
        })
        .catch(err => {
          this.$q.notify({
            message: "Delete error on DB",
            color: "red",
            timeout: 2000
          });
        });
    },

    //cancellazione dati
    deleteRow(element) {
      console.log("element", element);

      if (element.row.link) {
        this.deleteDb(element);
      } else {
        this.deleteFile(element.row.file)
          .then(() => {
            this.deleteDb(element);
          })
          .catch(err => {
            this.$q.notify({
              message: "Delete error on COS",
              color: "red",
              timeout: 2000
            });
          });
      }
    },

    //aggiunta file con link sul db
    addLink() {
      let newdocuments = this.datiOrginal;

      let obj = {
        name: this.filenameToAdd,
        file: this.linkToAdd,
        link: true,
        mdate: new Date()
      };
      newdocuments.push(obj);

      let toUpdate = {
        _id: this.id,
        documents: newdocuments
      };
      console.log("toUpdate", this.datiOrginal, toUpdate);

      this.baseUpdate(toUpdate)
        .then(results => {
          this.$q.notify({
            message: "Link successfull added",
            color: "green",
            timeout: 2000
          });
          console.info("Link successfull added");
          this.resetLink();
          this.flagArchive = false; // switch modalità
          this.getData();
        })
        .catch(err => {
          this.$q.notify({
            message: "Error on adding link",
            color: "red",
            timeout: 2000
          });
        });
    },

    resetLink() {
      this.linkToAdd = "";
      this.filenameToAdd = "";
    }
  }
};
</script>

<style>
.q-table--no-wrap th,
.q-table--no-wrap td .text-wrap {
  white-space: normal;
}
@media screen and (max-width: 1750px) {
  .q-table--no-wrap th,
  .q-table--no-wrap td {
    white-space: normal !important;
  }
}
</style>
