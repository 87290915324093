import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import TreeView from "../views/TreeView.vue";
import Login from "../views/Login.vue";
import Categorie from "../views/Categorie.vue";
import DocumentsReport from "../views/DocumentsReport.vue";
import UserReport from "../views/UserReport.vue";
import SizeYourSystemReport from "../views/SizeYourSystemReport.vue";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    alias: "/dashboard",
    beforeEnter: authGuard
  },
  {
    path: "*",
    redirect: "/dashboard",
    //component: Dashboard,
    beforeEnter: authGuard
  },
  {
    path: "/categorie/treeview/:id",
    name: "TreeView",
    component: TreeView,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/categorie",
    name: "Categorie",
    component: Categorie,
    beforeEnter: authGuard
  },
  {
    path: "/documentsreport",
    name: "DocumentsReport",
    component: DocumentsReport,
    beforeEnter: authGuard
  },
  {
    path: "/userreport",
    name: "UserReport",
    component: UserReport,
    beforeEnter: authGuard
  },
  {
    path: "/sizeyoursystemreport",
    name: "SizeYourSystemReport",
    component: SizeYourSystemReport,
    beforeEnter: authGuard
  }
];

const router = new VueRouter({
  routes
});

export default router;
