<template>
  <div class="q-pa-md q-mt-xs q-gutter-y-md">
    <div class="bg-white text-black">
      <q-toolbar>
        <q-toolbar-title>
          <h5 class="q-ma-none">Documents Report</h5>
        </q-toolbar-title>
        <q-space />
      </q-toolbar>
      <div class="q-pa-md q-gutter-y-sm">
        <div class="row max-width-100%">
          <q-input
            class="col col-md-8"
            style="max-width: 30%; margin-right: 10px"
            outlined
            color="black"
            dense
            v-model="filter"
            debounce="300"
            placeholder="Search Document"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>

          <q-select
            class="col col-md-6"
            style="max-width: 25%"
            clearable
            outlined
            dense
            v-model="cat_selected"
            map-options
            :options="cat_options"
            option-label="name"
            label="- all categories -"
            color="black"
          >
          </q-select>
          <!-- <q-btn
            class="col col-md-1"
            color="primary"
            text-color="white"
            style="margin-left:15px; max-width: 10%"
            >Search</q-btn
          > -->
        </div>
        <q-btn
          style="float: right"
          size="11px"
          no-caps
          color="black"
          label="Export in CSV"
          @click="exportTable()"
        />
      </div>
    </div>

    <div class="q-pa-md q-gutter-y-sm">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        class="doc-table"
        :data="dati"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination="pagination"
      >
        <template v-slot:body-cell-state="props">
          <q-td :props="props">
            <div v-if="!props.row.is_archive">
              <q-icon
                name="circle"
                class="text-green"
                style="font-size: 16px; line-height: 0.8;"
              />
              Online
            </div>

            <div v-if="props.row.is_archive">
              <q-icon
                name="circle"
                class="text-grey"
                style="font-size: 16px; line-height: 0.8;"
              />
              Archived
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              size="11px"
              no-caps
              color="primary"
              label="Detail"
              @click="openDetails(props)"
            />

            <detail-dialog
              :dialog="show"
              :source="sourceDetail"
              :detailName="detailName"
              @reset="reset()"
            >
            </detail-dialog>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import { date } from "quasar";
import DetailDialog from "../components/DetailDialog.vue";
import { exportFile } from "quasar";

export default {
  name: "DocumentsReport",
  components: {
    DetailDialog
  },
  data: () => ({
    show: false,
    cat_selected: "",
    dati: [],
    cat_options: [],
    detailName: "",
    sourceDetail: [],
    pagination: {
      sortBy: "document",
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "document",
        required: true,
        label: "DOCUMENTS",
        align: "left",
        field: "document",
        sortable: true
      },
      {
        name: "association",
        align: "left",
        label: "ASSOCIATION",
        field: "association",
        sortable: true
      },
      {
        name: "requests",
        align: "right",
        label: "N. REQUESTS",
        field: "requests",
        sortable: true
      },
      {
        name: "state",
        align: "left",
        label: "STATE",
        field: "is_archive",
        sortable: true
      },
      {
        name: "actions",
        align: "right",
        label: "ACTIONS"
      }
    ],
    filter: "",
    originalData: []
  }),

  mounted() {
    this.getData();
    this.getCategories();
  },

  created() {},
  watch: {
    cat_selected: function(newVal) {
      if (newVal) {
        let _this = this;
        this.dati = this.originalData.filter(row => {
          return row.categoryId === _this.cat_selected.id; //filtra le richieste nuove, non quelle precedenti al fix dell'11/12/2020
        });
      } else {
        this.dati = this.originalData;
      }
    }
  },
  computed: {
    /* pagesNumber() {
      if (this.this.pagination.rowsPerPage === 0)
      let tmp = Math.ceil(this.dati.length / this.pagination.rowsPerPage);
      console.log("temp", tmp);
      console.log("11221", this.pagination.rowsPerPage);

      return tmp > 5 ? 5 : tmp;
    }, */
    inputData() {
      return this.$store.getters.geRichieste;
    }
  },
  methods: {
    openDetails(data) {
      this.detailName = data.row.document;
      console.log("Selected Row", data);

      this.$http
        .get("/api/v1/document-report-detail", {
          params: {
            id: data.row.elementId,
            file: data.row.document
          }
        })
        .then(results => {
          this.sourceDetail = results.data;
          console.log("Data to detail", this.sourceDetail);
          this.show = true;
        });
    },

    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val;

      formatted =
        formatted === void 0 || formatted === null ? "" : String(formatted);

      formatted = formatted.split('"').join('""');

      return `"${formatted}"`;
    },

    exportTable() {
      var index = this.columns
        .map(function(item) {
          return item.name;
        })
        .indexOf("actions");

      const tempColumns = Array.from(this.columns);
      if (index !== -1) {
        tempColumns.splice(index);
      }

      let tempDati = this.originalData.map(function(elem) {
        return {
          association: elem.association,
          categoryId: elem.categoryId,
          document: elem.document,
          requests: elem.requests,
          is_archive: elem.is_archive ? "Archived" : "Online"
        };
      });

      const content = [tempColumns.map(col => this.wrapCsvValue(col.label))]
        .concat(
          tempDati.map(row =>
            tempColumns
              .map(col =>
                this.wrapCsvValue(
                  typeof col.field === "function"
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                  col.format
                )
              )
              .join(",")
          )
        )
        .join("\r\n");

      const status = exportFile("DocumentsReport.csv", content, "text/csv");

      if (status !== true) {
        this.$q.notify({
          message: "Browser denied file download...",
          color: "negative",
          icon: "warning"
        });
      }
    },

    getData() {
      this.$http.get("/api/v1/document-report").then(results => {
        this.dati = results.data;
        this.originalData = results.data;
      });
    },

    getCategories() {
      this.$http
        .get("/api/secure/categoria/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.cat_options = results.data.map(el => {
            return { id: el._id, name: el.name };
          });
          console.log("results", results);
          console.log("[Categories]:", results.data);
        });
    },

    reset() {
      this.show = false;
    }
  }
};
</script>
<style>
.doc-table .q-table tr td:first-child {
  width: 45%;
}
.doc-table.q-table--no-wrap td,
.doc-table.q-table--no-wrap th {
  white-space: normal !important;
}
</style>
