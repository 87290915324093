<template>
  <div>
    <q-dialog
      v-model="showUser"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card-section class="bg-white text-black">
        <q-toolbar class="text-black bg-white">
          <q-toolbar-title>
            <strong> {{ detailName }} </strong>
          </q-toolbar-title>
          <q-btn dense flat icon="close" v-close-popup @click="resetValue">
          </q-btn>
        </q-toolbar>
        <q-separator />
        <br />
        <div class="col">
          <div class="row max-width: 100%">
            <q-table
              class="col col-md-10 user-table-details"
              table-header-class="bg-blue-grey-1 text-grey-8"
              :data="source"
              :columns="columns"
              row-key="name"
              :grid="$q.screen.xs"
              :filter="filter"
              :pagination="pagination"
            >
              <template v-slot:body-cell-state="props">
                <q-td :props="props">
                  <div v-if="!props.row.is_archive">
                    <q-icon
                      name="circle"
                      class="text-green"
                      style="font-size: 16px; line-height: 0.8;"
                    />
                    Online
                  </div>

                  <div v-if="props.row.is_archive">
                    <q-icon
                      name="circle"
                      class="text-grey"
                      style="font-size: 16px; line-height: 0.8;"
                    />
                    Archiviato
                  </div>
                </q-td>
              </template>
            </q-table>
          </div>
          <!--  <div class="row justify-center q-mt-md">
            <q-pagination
              v-model="pagination.page"
              color="grey-8"
              :max="pagesNumber"
              size="md"
              :direction-links="true"
              :boundary-links="true"
              icon-first="skip_previous"
              icon-last="skip_next"
              icon-prev="fast_rewind"
              icon-next="fast_forward"
            />
          </div> -->
        </div>
      </q-card-section>
    </q-dialog>
  </div>
</template>

<script>
import { Dialog, date } from "quasar";

export default {
  name: "UserDetailDialog",
  props: ["dialog", "source", "detailName"],
  data: () => ({
    showUser: null,
    maximizedToggle: true,
    dati: [],
    pagination: {
      sortBy: "requestdate",
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "document",
        required: true,
        label: "DOCUMENTS",
        align: "left",
        field: "document",
        sortable: true
      },
      {
        name: "association",
        align: "left",
        label: "ASSOCIATION",
        field: "association",
        sortable: true
      },
      {
        name: "requestnumber",
        align: "right",
        label: "N. REQUESTS",
        field: "requests",
        sortable: true
      },
      {
        name: "state",
        align: "left",
        label: "STATE",
        field: "is_archive",
        sortable: true
      },
      {
        name: "requestdate",
        align: "right",
        label: "REQUEST DATE",
        field: "mdate",
        format: val => date.formatDate(val, "DD/MM/YYYY HH:mm:ss"),
        sortable: true
      }
    ],
    filter: ""
  }),

  mounted() {
    //console.log("sourceDetail", this.source);
  },
  computed: {
    showDialog() {
      return this.dialog;
    }
    /* pagesNumber() {
      let tmp = Math.ceil(this.source.length / this.pagination.rowsPerPage);
      return tmp > 5 ? 5 : tmp;
    } */
  },

  watch: {
    showDialog: function(newVal) {
      this.showUser = newVal;
      if (this.showUser) {
        if (this.source.id) {
          this.isNew = false;
        } else this.isNew = true;
      }
    }
  },

  methods: {
    //emit
    resetValue() {
      this.$emit("reset");
    }
  }
};
</script>

<style>
.user-table-details .q-table tr td:first-child {
  width: 45%;
}
</style>
