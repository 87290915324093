<template>
  <div class="q-pa-md q-mt-xs q-gutter-y-md">
    <div class="bg-white text-black">
      <q-toolbar>
        <q-toolbar-title>
          <h5 class="q-ma-none">User Report</h5>
        </q-toolbar-title>
        <q-space />
      </q-toolbar>

      <div class="q-pa-md q-gutter-y-sm">
        <div class="row max-width-100%">
          <q-input
            class="col col-md-8"
            style="max-width: 40%; margin-right: 10px"
            outlined
            color="black"
            dense
            v-model="filter"
            debounce="300"
            placeholder="Search User"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <q-btn
          style="float: right"
          size="11px"
          no-caps
          color="black"
          label="Export in CSV"
          @click="exportTable"
        />
      </div>
    </div>

    <div class="q-pa-md q-gutter-y-sm">
      <q-table
        table-header-class="bg-blue-grey-1 text-grey-8"
        :data="dati"
        :columns="columns"
        row-key="user"
        :grid="$q.screen.xs"
        :filter="filter"
        :pagination="pagination"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              size="11px"
              no-caps
              color="primary"
              label="Detail"
              @click="openDetails(props)"
            />

            <user-detail-dialog
              :dialog="show"
              :source="sourceDetail"
              :detailName="detailName"
              @reset="reset()"
            >
            </user-detail-dialog>
          </q-td>
        </template>
      </q-table>
      <!-- 
      <div class="row justify-center q-mt-md">
        <q-pagination
          v-model="pagination.page"
          color="grey-8"
          :max="pagesNumber"
          size="md"
          :direction-links="true"
          :boundary-links="true"
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { date } from "quasar";
import UserDetailDialog from "../components/UserDetailDialog.vue";
import { exportFile } from "quasar";

export default {
  name: "UserReport",
  components: {
    UserDetailDialog
  },
  data: () => ({
    show: false,
    dati: [],
    detailName: null,
    sourceDetail: [],
    pagination: {
      sortBy: "user",
      descending: false,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "user",
        required: true,
        label: "USER",
        align: "left",
        field: "email",
        sortable: true
      },
      {
        name: "fullname",
        align: "left",
        label: "FULL NAME",
        field: "username",
        sortable: true
      },
      {
        name: "company",
        align: "left",
        label: "COMPANY",
        field: "company",
        sortable: true
      },
      {
        name: "phone",
        align: "left",
        label: "PHONE",
        field: "phone",
        sortable: true
      },
      {
        name: "totalrequests",
        align: "right",
        label: "N. REQUESTS",
        field: "count",
        sortable: true
      },
      {
        name: "actions",
        align: "right",
        label: "ACTIONS"
      }
    ],
    filter: ""
  }),

  created() {},
  mounted() {
    this.getData();
  },

  computed: {
    /*  pagesNumber() {
      let tmp = Math.ceil(this.dati.length / this.pagination.rowsPerPage);
      return tmp > 5 ? 5 : tmp;
    }, */
    inputData() {
      return this.$store.getters.getRichieste;
    }
  },
  methods: {
    openDetails(data) {
      this.detailName = data.row.email;
      console.log("Selected Row", data);

      this.$http
        .get("/api/v1/user-report-detail", {
          params: {
            email: this.detailName,
            file: data.row.document
          }
        })
        .then(results => {
          this.sourceDetail = results.data;
          console.log("Data to detail", this.sourceDetail);
          this.show = true;
        });
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val;

      formatted =
        formatted === void 0 || formatted === null ? "" : String(formatted);

      formatted = formatted.split('"').join('""');

      return `"${formatted}"`;
    },

    exportTable() {
      var index = this.columns
        .map(function(item) {
          return item.name;
        })
        .indexOf("actions");

      if (index !== -1) {
        const tempColumns = Array.from(this.columns);
        tempColumns.splice(index);

        const content = [tempColumns.map(col => this.wrapCsvValue(col.label))]
          .concat(
            this.dati.map(row =>
              tempColumns
                .map(col =>
                  this.wrapCsvValue(
                    typeof col.field === "function"
                      ? col.field(row)
                      : row[col.field === void 0 ? col.name : col.field],
                    col.format
                  )
                )
                .join(",")
            )
          )
          .join("\r\n");

        const status = exportFile("UserReport.csv", content, "text/csv");

        if (status !== true) {
          this.$q.notify({
            message: "Browser denied file download...",
            color: "negative",
            icon: "warning"
          });
        }
      }
    },

    getData() {
      this.$http.get("/api/v1/user-report").then(results => {
        this.dati = results.data;
      });
    },

    reset() {
      this.show = false;
    }
  }
};
</script>
