<template>
  <div class="q-pa-md q-mt-xs q-gutter-md">
    <q-toolbar>
      <q-toolbar-title>
        <h5 class="q-ma-none">Catalog Categories</h5>
      </q-toolbar-title>

      <div v-if="isAdmin" class="q-gutter-md">
        <q-btn
          color="deep-orange"
          no-caps
          label="Add Category"
          @click="show = true"
        />
        <category-dialog :dialog="show" @reset="reset()"> </category-dialog>
      </div>
    </q-toolbar>

    <div class="row q-gutter-sm">
      <div v-for="el in elements" :key="el._id">
        <categorie-card
          :id="el._id"
          :imgproducts="el.imgproducts"
          :shortname="el.shortname"
          :name="el.name"
          class="card-category"
        >
        </categorie-card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CategorieCard from "@/components/CategorieCard.vue";
import CategoryDialog from "../components/CategoryDialog.vue";

export default {
  name: "Categorie",
  components: {
    CategorieCard,
    CategoryDialog
  },

  data: () => ({
    elements: [],
    show: false
  }),

  mounted() {
    this.getData();
    console.log(this.show);
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },

  methods: {
    reset() {
      this.show = false;
      this.getData();
    },
    getData() {
      this.$http
        .get("/api/secure/categoria/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.elements = results.data;
          console.log("results", results);
          console.log("[Categories]:", results.data);
        });
    }
  }
};
</script>

<style></style>
