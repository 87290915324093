import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const myfn = () => {
    console.log("authGuard", authService.isAuthenticated);
    if (authService.isAuthenticated) {
      //console.log("normalmente autenticato");

      if (localStorage.getItem("profile")) {
        var exp = parseInt(localStorage.getItem("expires"));
        var now = Date.now(); //Math.floor(Date.now());
        //console.log("token time diff", now, exp);
        if (now > exp) {
          console.log("token scaduto");
          localStorage.removeItem("expires");
          localStorage.removeItem("token");
          localStorage.removeItem("profile");
          authService.isAuthenticated = false;
          return next({
            path: "/login",
            query: { redirect: to.path }
          });
        }
      }

      return next();
    } else {
      //console.log("per lui NON autenticato");
      return next({
        path: "/login",
        query: { redirect: to.path }
      });
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (authService) {
    if (!authService.loading) {
      return myfn();
    }
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return myfn();
    }
  });
};
