<template>
  <q-layout view="hHh lpR fFf">
    <q-header
      elevated
      class="bg-secondary text-white"
      v-if="$auth.isAuthenticated"
    >
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="left = !left" />

        <q-toolbar-title>
          <router-link class="linkClick" to="/dashboard"
            ><img class="logo" src="./assets/logo/logo-white.svg"
          /></router-link>
        </q-toolbar-title>
        <q-btn
          v-if="$auth.isAuthenticated && getUser.picture"
          round
          style="margin-right:5px;"
        >
          <q-avatar>
            <img class="avatar" :src="getUser.picture" />
          </q-avatar>
        </q-btn>

        <q-btn-dropdown
          v-if="$auth.isAuthenticated"
          flat
          no-caps
          :label="getUser.user_metadata.name"
          style="margin-right:10px;"
        >
          <q-list>
            <q-item clickable v-close-popup @click="logout">
              <q-item-section>
                <q-item-label>Log out</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="$auth.isAuthenticated"
      show-if-above
      v-model="left"
      side="left"
      bordered
    >
      <q-item class="q-mt-md" clickable to="/dashbard">
        <q-item-section avatar>
          <q-icon name="dashboard" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Dashboard</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable to="/categorie">
        <q-item-section avatar>
          <q-icon name="topic" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Catalog</q-item-label>
        </q-item-section>
      </q-item>

      <q-list>
        <q-expansion-item icon="bar_chart" label="Report" default-opened>
          <q-item
            :inset-level="1"
            clickable
            to="/documentsreport"
            class="q-mt-sm"
          >
            <q-item-label>Documents Report</q-item-label>
          </q-item>

          <q-item :inset-level="1" clickable to="/userreport">
            <q-item-label>User Reports</q-item-label>
          </q-item>

          <q-item :inset-level="1" clickable to="/sizeyoursystemreport">
            <q-item-label>Size Your System Report</q-item-label>
          </q-item>
        </q-expansion-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: "LayoutDefault",

  components: {},
  data: () => ({
    left: false,
    logo: process.env.VUE_APP_LOGO
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  mounted() {},
  methods: {
    getLogo() {},

    logout() {
      this.$auth.logout(window.location.origin);
    }
  }
};
</script>

<style>
.logo {
  height: 50px;
  margin-top: 10px;
}
.avatar {
  font-size: 30px;
}
.linkClick:active {
  position: relative;
  top: 1px;
  left: 1px;
}
</style>
