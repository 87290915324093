<template>
  <q-page v-if="!$auth.isAuthenticated" class="pg-login">
    <div class="row image bg-login">
      <div
        class="bg-image"
        :style="{
          'background-image': 'url(' + require('../assets/sys.jpg') + ')'
        }"
      ></div>
    </div>
    <div class="bg-card">
      <div class="column">
        <div class="row">
          <q-card square bordered class="q-pa-lg shadow-1 text-center">
            <q-card-section>
              <q-form class="q-gutter-md">
                <img alt="Alfalaval" src="../assets/logo/logo-alfalaval.svg" />
                <h4 class="q-pt-md q-mb-none">
                  <strong>Size Your System</strong>
                </h4>
                <h5>Backend</h5>
              </q-form>
            </q-card-section>
            <q-card-actions class="q-px-md">
              <q-btn
                unelevated
                color="primary"
                size="lg"
                class="full-width"
                label="Login"
                @click="login"
              />
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>

  <!-- <div v-if="!$auth.loading">
    <div class="fit row justify-center"  :style="{
          'background-image':
            'url(' + require('../assets/background.png') + ')',  
        }" >>
   <img alt="Vue logo" src="../assets/background.png" /> 
     
      <button v-if="!$auth.isAuthenticated" @click="login">Log in</button>

      <button v-if="$auth.isAuthenticated" @click="logout">Log out</button>
    </div>
  </div> -->
</template>

<script>
export default {
  methods: {
    // Log the user in
    login() {
      //apre un popup
      //this.$auth.loginWithPopup();

      //redirect nella pagina di auth0 e successivo ritorno in home
      this.$auth.login();
    },
    // Log the user out
    logout() {
      this.$auth.logout(window.location.origin);
    }
  }
};
</script>

<style>
.pg-login {
  position: relative;
}
.bg-login {
  width: 100%;
  height: 400px;
}
.bg-image {
  width: 100%;
  background-size: cover;
}
.bg-card {
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  top: -10em;
}
@media screen and (max-width: 650px) {
  .bg-card {
    top: -20em;
  }
}
</style>
