<template>
  <div>
    <q-splitter v-model="outsideModel" horizontal>
      <template v-slot:before>
        <div
          class="q-pa-lg q-mt-xs fit column wrap justify-start items-start content-start"
        >
          <q-btn
            align="around"
            flat
            color="primary"
            padding="sm none "
            size="sm"
            label="Categories"
            icon="keyboard_arrow_left"
            to="/categorie"
          />
          <h4 class="q-ma-none">{{ getCategoryName }}</h4>
        </div>
      </template>

      <q-splitter v-model="splitterModel" style="height: auto">
        <template v-slot:before>
          <div class="q-pa-md">
            <q-tree
              ref="albero"
              :nodes="dati"
              node-key="label"
              selected-color="primary"
              :selected.sync="selected"
              :expanded.sync="expanded"
            />
          </div>
          <div>
            <q-btn
              v-if="isAdmin"
              style="margin: 10px"
              color="deep-orange"
              no-caps
              label="Add Model"
              @click="onClick('modello')"
            />

            <q-btn
              v-if="isAdmin"
              :disabled="isDisabled"
              style="margin: 10px"
              color="deep-orange"
              no-caps
              label="Add Product"
              @click="onClick('prodotto')"
            />
          </div>
        </template>

        <template v-slot:after>
          <q-splitter v-model="insideModel" horizontal class="splitter-title">
            <template v-slot:before>
              <div class="q-pa-md">
                <div class="text-h5 q-mb-md">
                  {{ selected }}
                  <q-btn
                    v-if="isAdmin"
                    style="float:right"
                    color="deep-orange"
                    no-caps
                    label="Edit info for website"
                    @click="onClick(currentNode.type, 'update')"
                  />
                </div>
              </div>
            </template>

            <template v-slot:after>
              <div class="q-pa-md">
                <uploader
                  :type="currentNode.type"
                  :id="currentNode.id"
                ></uploader>
              </div>
            </template>
          </q-splitter>
        </template>
      </q-splitter>
    </q-splitter>
    <category-dialog :dialog="showCategory" :dati="currentData" @reset="reset">
    </category-dialog>
    <model-dialog :dialog="showModel" :dati="currentData" @reset="reset">
    </model-dialog>
    <product-dialog
      :dialog="showProduct"
      :dati="currentData"
      :currentModel="currentNode.id"
      @reset="reset"
    >
    </product-dialog>
  </div>
</template>

<script>
import Uploader from "../components/Uploader.vue";
import ModelDialog from "../components/ModelDialog.vue";
import ProductDialog from "../components/ProductDialog.vue";
import CategoryDialog from "../components/CategoryDialog.vue";

export default {
  name: "TreeView",
  components: {
    Uploader,
    ModelDialog,
    ProductDialog,
    CategoryDialog
  },
  data: () => ({
    selected: "",
    currentNode: {},
    splitterModel: 20,
    outsideModel: 50,
    insideModel: 20,
    dati: [],
    expanded: [],
    showCategory: false,
    showModel: false,
    currentData: [],
    showProduct: false
  }),

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    isDisabled: function() {
      return this.currentNode.type !== "modello";
    },

    getCategoryName() {
      return this.$store.getters.getCategoryName;
    },

    getCategoryShortName() {
      return this.$store.getters.getCategoryShortName;
    }
  },

  watch: {
    selected: function(newv, old) {
      let obj = null;
      if (!newv && old) this.selected = old;
      if (newv) obj = this.$refs.albero.getNodeByKey(newv);
      console.log("[Current node]", obj);
      if (obj !== null) this.currentNode = obj;
    }
  },

  mounted() {
    this.getDataTree();
  },

  methods: {
    onClick(type, action) {
      if (action !== "update") {
        this.currentData = [];
      } else {
        this.currentData = this.currentNode;
      }

      switch (type) {
        case "categoria":
          this.showCategory = true;
          break;
        case "modello":
          this.showModel = true;
          break;
        case "prodotto":
          this.showProduct = true;
      }
    },

    reset() {
      this.showCategory = false;
      this.showModel = false;
      this.showProduct = false;
      this.getDataTree();
    },

    getDataTree() {
      this.$http
        .get("/api/v1/get-tree", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          params: { id: this.$route.params.id }
        })
        .then(results => {
          // console.log("[Data tree]:", results.data);
          //aggiungo la root da client
          let completeData = [];
          let rootObj = {};
          rootObj.label = this.getCategoryName;
          rootObj.id = this.$route.params.id;
          rootObj.type = "categoria";
          rootObj.children = results.data;

          completeData.push(rootObj);

          this.currentNode = rootObj;
          this.expanded.push(this.getCategoryName);
          this.selected = this.getCategoryName;
          this.dati = completeData;

          console.log("[Data tree]:", completeData);
        });
    }
  }
};
</script>

<style>
.splitter-title .q-splitter__separator {
  display: none;
}
</style>
