<template>
  <div>
    <q-dialog
      v-model="showProduct"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card-section class="bg-white text-black">
        <q-toolbar class="text-black bg-white">
          <q-toolbar-title>
            <strong>{{ getTitle }}</strong>
          </q-toolbar-title>
          <q-btn dense flat icon="close" v-close-popup @click="resetValue">
          </q-btn>
        </q-toolbar>
        <q-separator />
        <br />
        <div class="q-pa-md">
          <div class="row max-width-100%">
            <q-form @submit="isNew ? save() : update()" class="col">
              <q-input
                outlined
                v-model="name"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Name"
              />
              <q-input
                outlined
                v-model="slug"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Slug"
              />
              <q-input
                outlined
                v-model="size"
                style="max-width: 25%; margin-bottom: 15px"
                dense
                label="Size"
              />
              <label><small>Abstract</small></label>
              <q-editor
                dense
                style="max-width: 80%; margin-bottom: 15px"
                ref="abstract"
                outlined
                v-model="abstract"
                placeholder="Abstract"
                :toolbar="[
                  [
                    'bold',
                    'italic',
                    'strike',
                    'underline',
                    'subscript',
                    'superscript'
                  ],
                  ['token', 'hr', 'link', 'custom_btn'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
                  ['undo', 'redo']
                ]"
              />

              <q-table
                v-if="isAdmin"
                title="Other properties"
                :data="other"
                :columns="columns"
                row-key="id"
                :filter="filter"
                :loading="loading"
              >
                <template v-slot:top>
                  <q-btn
                    color="primary"
                    :disable="loading"
                    label="Add row"
                    @click="addRow()"
                  />
                  <q-space />
                  <q-input
                    borderless
                    dense
                    debounce="300"
                    color="primary"
                    v-model="filter"
                  >
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>

                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td key="id" :props="props">{{ props.row.id }}</q-td>
                    <q-td key="property" :props="props">
                      {{ props.row.property }}
                      <q-popup-edit ref="inline" v-model="props.row.property">
                        <q-input
                          v-model="props.row.property"
                          dense
                          autofocus
                          counter
                        />
                      </q-popup-edit>
                    </q-td>
                    <q-td key="value" :props="props">
                      {{ props.row.value }}
                      <q-popup-edit ref="inline" v-model="props.row.value">
                        <q-input
                          v-model="props.row.value"
                          dense
                          autofocus
                          counter
                        />
                      </q-popup-edit>
                    </q-td>
                    <q-td key="actions" :props="props">
                      <q-btn
                        icon="delete"
                        flat
                        round
                        dense
                        @click="remove(props.row.id)"
                      />
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
              <br />

              <div>
                <q-btn
                  v-if="isNew"
                  label="SAVE"
                  type="submit"
                  color="black"
                  v-close-popup
                />
                <q-btn
                  v-if="!isNew"
                  label="UPDATE"
                  type="submit"
                  color="black"
                  v-close-popup
                />
              </div>
            </q-form>
            <q-form class="col-6 col-md-4">
              <q-uploader
                v-if="this.cover === ''"
                :url="uploadUrl"
                label="Cover"
                style="max-width: 300px; margin-bottom: 20px"
                @uploaded="setCover"
                color="white"
                text-color="black"
              />
              <q-card
                class="col-md-3 text-subtitle text-center"
                style="margin: 10px"
                v-if="this.cover !== ''"
              >
                <q-img :src="getImageCover" style="ratio: 1">
                  <div class="absolute-bottom-right text-subtitle1 text-right">
                    <q-btn no-caps color="red" @click="deleteCover()">
                      <q-icon left size="2em" name="delete" />
                      <div>Delete</div>
                    </q-btn>
                  </div>
                  <div class="absolute-bottom-left text-subtitle1 text-left">
                    Cover Preview
                  </div>
                </q-img>
              </q-card>

              <q-uploader
                v-if="this.imgproducts === ''"
                :url="uploadUrl"
                label="Image Product"
                style="max-width: 300px"
                @uploaded="setImgProd"
                color="white"
                text-color="black"
              />
              <q-card
                class="col-md-3"
                style="margin: 10px"
                v-if="this.imgproducts !== ''"
              >
                <q-img :src="getImageProduct" style="ratio: 1">
                  <div class="absolute-bottom-right text-subtitle1 text-right">
                    <q-btn no-caps color="red" @click="deleteImgproducts()">
                      <q-icon left size="2em" name="delete" />
                      <div>Delete</div>
                    </q-btn>
                  </div>
                  <div class="absolute-bottom-left text-subtitle1 text-left">
                    Image Product Preview
                  </div>
                </q-img>
              </q-card>
            </q-form>
          </div>
        </div>
      </q-card-section>
    </q-dialog>
  </div>
</template>

<script>
import { Dialog } from "quasar";
export default {
  name: "ProductDialog",
  props: ["type", "id", "dialog", "dati", "currentModel"],
  data: () => ({
    showProduct: null,
    isNew: true,
    maximizedToggle: true,
    name: "",
    slug: "",
    size: "",
    abstract: "",
    uploadUrl: null,
    modello: "",
    imgproducts: "",
    cover: "",
    loading: false,
    columns: [
      {
        name: "id",
        required: true,
        label: "Id",
        align: "left",
        field: row => row.id,
        sortable: true
      },
      {
        name: "property",
        align: "center",
        label: "Property",
        field: "property",
        sortable: true
      },
      {
        name: "value",
        align: "center",
        label: "Value",
        field: "value",
        sortable: true
      },
      {
        name: "actions",
        align: "center",
        label: "Actions"
      }
    ],
    filter: "",
    other: [
      {
        id: 1,
        property: "",
        value: ""
      },
      {
        id: 2,
        property: "",
        value: ""
      }
    ]
  }),

  mounted() {
    this.getModelli();
    this.uploadUrl = process.env.VUE_APP_BASEURL + "/api/v1/cloud-upload";
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    showDialog() {
      return this.dialog;
    },
    getTitle() {
      return this.isNew ? "Add Product" : "Edit Product";
    },
    getImageProduct() {
      return (
        "https://res.cloudinary.com/plurimedia/image/upload/" + this.imgproducts
      );
    },
    getImageCover() {
      return "https://res.cloudinary.com/plurimedia/image/upload/" + this.cover;
    }
  },

  watch: {
    showDialog: function(newVal) {
      this.showProduct = newVal;
      if (this.showProduct) {
        if (this.dati.id) {
          this.getData();
          this.isNew = false;
        } else this.isNew = true;
      }
    }
  },

  methods: {
    /* pasteCapture(evt, type) {
      let text, onPasteStripFormattingIEPaste;
      evt.preventDefault();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData("text/plain");
        this.$refs[type].runCmd("insertText", text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData("text/plain");
        this.$refs[type].runCmd("insertText", text);
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          this.$refs[type].runCmd("ms-pasteTextOnly", text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    }, */

    addRow() {
      this.loading = true;
      console.log("other", this.other);
      const index = this.other.length;
      const newRow = {
        id: index + 1,
        property: "",
        value: ""
      };
      this.other.push(newRow);
      this.loading = false;
    },

    remove(_id) {
      this.loading = true;
      this.other = this.other.filter(function(value, index, arr) {
        return value.id !== _id;
      });
      console.log("remove", this.other, _id);
      this.loading = false;
    },

    getData() {
      if (this.dati.type) {
        this.$http
          .get("/api/secure/" + this.dati.type + "/get-by-id/?", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken
            },
            params: { id: this.dati.id }
          })
          .then(results => {
            this.elements = results.data;
            console.log("[Elemento in dialog]:", results.data);
            let elem = results.data[0];
            this.name = elem.name;
            this.slug = elem.slug;
            this.size = elem.size;
            this.abstract = elem.abstract;
            this.cover = elem.cover;
            this.imgproducts = elem.imgproducts;
            this.other = elem.other ? elem.other : [];
          });
      }
    },
    //emit
    resetValue() {
      this.name = "";
      this.slug = "";
      this.abstract = "";
      this.subtitle = "";
      this.size = "";
      this.cover = "";
      this.imgproducts = "";
      this.modello = "";
      this.other = [];
      this.$emit("reset");
    },

    getModelli() {
      this.$http
        .get("/api/secure/modello/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.models = results.data;
        });
    },

    deleteImgproducts() {
      this.imgproducts = "";
    },

    deleteCover() {
      this.cover = "";
    },

    setCover(info) {
      console.log("Result from cloudinary", info);
      let resp = JSON.parse(info.xhr.responseText);
      // console.log("Json cloudinary", resp);
      this.cover = resp.public_id;
    },

    setImgProd(info) {
      console.log("Result from cloudinary", info);
      let resp = JSON.parse(info.xhr.responseText);
      //console.log("Json cloudinary", resp);
      this.imgproducts = resp.public_id;
    },

    save() {
      let toSave = {
        name: this.name,
        slug: this.slug,
        abstract: this.abstract,
        size: this.size,
        cover: this.cover,
        imgproducts: this.imgproducts,
        modello: this.currentModel,
        other: this.other
      };
      this.$http
        .post("/api/secure/prodotto/save", toSave, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.$q.notify({
            message: "Salvataggio riuscito",
            color: "green",
            timeout: 1000
          });
          console.info("Salvataggio riuscito");
          this.resetValue();
        })
        .catch(err => {
          this.$q.notify({
            message: "Errore durante il salvataggio",
            color: "red",
            timeout: 1000
          });
          this.resetValue();
        });
    },
    update() {
      let toUpdate = {
        _id: this.dati.id,
        name: this.name,
        slug: this.slug,
        abstract: this.abstract,
        size: this.size,
        cover: this.cover,
        imgproducts: this.imgproducts,
        other: this.other
      };
      this.$http
        .put("/api/secure/prodotto/update", toUpdate, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.$q.notify({
            message: "Aggiornamento riuscito",
            color: "green",
            timeout: 1000
          });
          console.info(
            "Aggiornamento riuscito modello id:" + this.$route.params.id
          );
          this.resetValue();
        })
        .catch(err => {
          this.$q.notify({
            message: "Errore durante l'aggiornamento",
            color: "red",
            timeout: 1000
          });
          this.resetValue();
        });
    }
  }
};
</script>
