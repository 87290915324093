import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Auth0Plugin } from "./auth";
import "./quasar";
import Axios from "axios";
import _ from "lodash";

Vue.prototype.$http = Axios;
Vue.prototype.$_ = _;

// Import the Auth0 configuration
var domain = process.env.VUE_APP_DOMAIN;
var clientId = process.env.VUE_APP_CLIENTID;

// **** Auth0 ****
Vue.use(Auth0Plugin, {
  domain,
  clientId
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
