<template>
  <div>
    <q-dialog
      v-model="showDetail"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card-section class="bg-white text-black">
        <q-toolbar class="text-black bg-white">
          <q-toolbar-title>
            <strong> {{ detailName }} </strong>
          </q-toolbar-title>
          <q-btn dense flat icon="close" v-close-popup @click="resetValue">
          </q-btn>
        </q-toolbar>
        <q-separator />
        <br />
        <div class="col">
          <div class="row max-width: 100%">
            <q-table
              class="col col-md-8"
              table-header-class="bg-blue-grey-1 text-grey-8"
              :data="source"
              :columns="columns"
              row-key="name"
              :grid="$q.screen.xs"
              :filter="filter"
              :pagination="pagination"
            >
            </q-table>
          </div>
          <!-- <div class="row justify-center q-mt-md">
            <q-pagination
              v-model="pagination.page"
              color="grey-8"
              :max="pagesNumber"
              size="md"
              :direction-links="true"
              :boundary-links="true"
              icon-first="skip_previous"
              icon-last="skip_next"
              icon-prev="fast_rewind"
              icon-next="fast_forward"
            />
          </div> -->
        </div>
      </q-card-section>
    </q-dialog>
  </div>
</template>

<script>
import { Dialog, date } from "quasar";

export default {
  name: "DetailDialog",
  props: ["dialog", "source", "detailName"],
  data: () => ({
    showDetail: null,
    maximizedToggle: true,
    pagination: {
      sortBy: "requestdate",
      descending: true,
      page: 1,
      rowsPerPage: 10
    },
    columns: [
      {
        name: "user",
        required: true,
        label: "USER",
        align: "left",
        field: "email",
        sortable: true
      },
      {
        name: "fullname",
        align: "left",
        label: "FULL NAME",
        field: "username",
        sortable: true
      },
      {
        name: "company",
        align: "left",
        label: "COMPANY",
        field: "company",
        sortable: true
      },
      {
        name: "phone",
        align: "left",
        label: "PHONE",
        field: "phone",
        sortable: true
      },
      {
        name: "requestdate",
        align: "right",
        label: "REQUEST DATE",
        field: "mdate",
        format: val => date.formatDate(val, "DD/MM/YYYY HH:mm:ss"),
        sortable: true
      }
    ],
    filter: ""
  }),

  mounted() {
    console.log("source", this.source);
  },
  computed: {
    showDialog() {
      return this.dialog;
    }
    /* pagesNumber() {
      let tmp = Math.ceil(this.source.length / this.pagination.rowsPerPage);
      return tmp > 5 ? 5 : tmp;
    } */
  },

  watch: {
    showDialog: function(newVal) {
      this.showDetail = newVal;
      if (this.showDetail) {
        if (this.source.id) {
          this.isNew = false;
        } else this.isNew = true;
      }
    }
  },

  methods: {
    //emit
    resetValue() {
      this.$emit("reset");
    },

    getCategorie() {
      this.$http
        .get("/api/secure/categoria/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        })
        .then(results => {
          this.cat = results.data;
        });
    }
  }
};
</script>
